@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700;800&display=swap');

html, body {
    margin: 0;
    padding: 0;
    text-align: center;
}

h2 {
    font-style: italic;
}

* {
    font-family: 'Readex Pro', sans-serif;
}

.primary-title {
    font-size: 40px;
    color: rgb(255, 255, 255);
}

.primary-button {
    color: rgb(255,255,255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, #12c2e9,#c471ed,#f64f59 );
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
}

.primary-button:hover {
    background: linear-gradient(260deg,  #12c2e9,#c471ed,#f64f59);
}

.secondary-button {
    color: rgb(121, 119, 119);
    background-color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    padding: 12px 30px;
    border-radius: 30px;
    border: solid 2px rgb(121, 119, 119);
    margin: 6px;
}

.secondary-button:hover {
    color: rgb(48, 48, 48);
    border: solid 2px rgb(48, 48, 48);
}


/*-------- Navbar --------*/

nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

nav .nav-button {
    background: linear-gradient(260deg,  #12c2e9,#c471ed,#f64f59);
    background-color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    margin: 10px;
}

nav .nav-button:disabled {
    background-color: rgb(181, 180, 180);
    color: rgb(189, 42, 90);
}

.logo-container h2 {
    background: linear-gradient(260deg, #12c2e9,#c471ed,#f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    background-clip: text;
  }


  
nav .logo-container {
    width: 250px;
    margin: 20px;
}

nav .logo-container .logo {
    width: 100%;
}

/*-------- Home page --------*/
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.65) 0%,
            rgba(0, 0, 0, 0) 100%
    ), url('images/masahiro-miyagi-AzK6UFPNYPc-unsplash.jpg');
    background-size: cover;
    background-position: center;
}
.home h1 {
    font-size: 55px;
}
.home {
    margin-top: 30vh;
}

/*-------- Auth Modal --------*/
.auth-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin: 0 auto;
    max-width: 360px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 40px;
}

.auth-modal .close-icon {
    float: right;
}

.auth-modal form h1 {
    align-self: flex-start;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 17px;
  }
  
.auth-modal form {
    display: flex;
    flex-direction: column;
}

.auth-modal input[type=password],
.auth-modal input[type=email] {
    padding: 8px;
    margin: 8px;
    font-size: 17px;
}


/*-------- OnBoarding Page --------*/
.onboarding {
    border-top: solid 1px rgb(213, 213, 213);
    padding: 20px;
}

.onboarding form {
    display: flex;
    flex-direction: column;
    align-content: center;
}
.or h2{
  text-align: center;
}
.onboarding form section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    text-align: start;
}

.onboarding form input {
    padding: 15px 30px;
    margin: 10px 0;
    font-size: 15px;
    border: solid 2px rgb(219, 219, 219);
    border-radius: 20px;
    width: 100%;
    /*box-sizing: border-box;*/
}

.onboarding form label {
    margin: 10px 0;
}

.onboarding form .multiple-input-container {
    display: flex;
    flex-direction: row;
}

.onboarding form input::-webkit-outer-spin-button,
.onboarding form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.onboarding form .multiple-input-container input {
    margin-right: 10px;
}

.onboarding form .multiple-input-container label {
    padding: 10px;
    border: solid 2px rgb(144, 144, 144);
    border-radius: 10px;
    transition: all 0.3s;
    margin-right: 10px;
}

.onboarding form input[type=radio] {
    display: none;
}

.onboarding form input[type=number] {
    width: 10%;
}

.onboarding form input[type=radio]:checked + label {
    border: solid 2px rgb(221, 39, 22);
}

.onboarding form input[type=submit]:hover {
    background-color: rgb(235, 235, 235);
}

.onboarding form input[type=submit]:active {
    background-color: rgb(226, 115, 115);
}

.onboarding form .photo-container img {
    width: 100%
}


/*-------- Dashboard  --------*/

  .card-container {
    margin: auto;
    width: 50%;
    height: 100vh;
    align-items: center;
    
  }
  
  .card {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 600px;
    height: 750px; /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
    border-radius: 20px;
   
  }

  
  
  .card-info-container {
    position: absolute;
    top: 0;
    left: 600px; /* Change to right */
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    width: 500px; /* Adjust the width as needed */
    height: 663px; /* Adjust the height as needed */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 20px;
  }
  
  .card-buttons {
    position: absolute;
    bottom: 20px;
    right: 50px;
  }

  .message {
    font-size: 18px;
    color: #261ad1;
  }
  
  .card-buttons {
    align-items: row;
  }
  .connect-button-styling button {
    padding: 10px 20px;
    background: linear-gradient(260deg, #c2e912, #edd071, #f6b34f);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .card-buttons
  .back-button-styling button {
    padding: 10px 20px;
    background: linear-gradient(260deg, #e91264, #ed71d2, #e34ff6);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .next-button-styling button {
    padding: 10px 20px;
    background: linear-gradient(260deg, #e91264, #ed71d2, #e34ff6);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .connect-button {
    -webkit-background-clip: text;
    font-size: 30px;
    background-clip: text;
    float: left;
  }
  
  
  .back-button {
    /* Add your existing styles */
    -webkit-background-clip: text;
    font-size: 30px;
    background-clip: text;
    float: left;
  }
  
  .next-button {
    /* Add your existing styles */
    -webkit-background-clip: text;
    font-size: 30px;
    background-clip: text;
    
  }
  
  
  .card-buttons button:hover {
    background-color: #ff3333;
  }

  @media screen and (max-width: 1000px) {

    .card-container {
      margin: 0px;
      width: 100vw;
      height: 100vh;
      
    }
    
    .card {
      justify-content: center;
      align-items: center;
      position: relative;
      height: 89%; /* Adjust the height as needed */
      width: 80%;
      background-size: cover;
      background-position: center;
      border-radius: 20px;
      display: block;
      margin-left: auto;
      margin-right: auto;
     
    }
    
    
    .card-info-container {
      display: none;
    }
    
    .card-buttons {
      position: absolute;
      bottom: 20px;
      right: 50px;
    }
  
    .message {
      font-size: 18px;
      color: #261ad1;
    }
    
    .connect-button-styling button {
      padding: 10px 20px;
      background: linear-gradient(260deg, #c2e912, #edd071, #f6b34f);
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .back-button-styling button {
      padding: 10px 20px;
      background: linear-gradient(260deg, #e91264, #ed71d2, #e34ff6);
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  
    .next-button-styling button {
      padding: 10px 20px;
      background: linear-gradient(260deg, #e91264, #ed71d2, #e34ff6);
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .connect-button {
      -webkit-background-clip: text;
      font-size: 30px;
      background-clip: text;
      float: left;
 
    }
    
    
    .back-button {
      /* Add your existing styles */
      -webkit-background-clip: text;
      font-size: 30px;
      background-clip: text;
      float: left;
      margin-right: 0px;
  
      
    }
    
    .next-button {
      /* Add your existing styles */
      -webkit-background-clip: text;
      font-size: 30px;
      background-clip: text;
      float: right;
      margin-left: 0px;
      
    }
    
    
    .card-buttons button:hover {
      background-color: #ff3333;
    }
  
  }
  
  @media screen and (max-width: 600px) {
    
    .card {
      justify-content: center;
      align-items: center;
      position: relative;
      height: 89%; /* Adjust the height as needed */
      width: 80%;
      background-size: cover;
      background-position: center;
      border-radius: 20px;
      display: block;
      margin-left: auto;
      margin-right: auto;
     
    }
  
    
    
    .card-info-container {
      display: none;
    }
    
    .card-buttons {
      position: absolute;
      bottom: 20px;
      right: 50px;
    }
  
    .message {
      font-size: 18px;
      color: #261ad1;
    }
    
    .card-buttons button {
      padding: 10px 20px;
      background: linear-gradient(260deg, #12c2e9, #c471ed, #f64f59);
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .connect-button {
      -webkit-background-clip: text;
      font-size: 30px;
      background-clip: text;
      float: left;
      margin-left: 0px;
    }
    
    
    .back-button {
      /* Add your existing styles */
      -webkit-background-clip: text;
      font-size: 30px;
      background-clip: text;
      float: left;
      margin-right: 0px;
  
      
    }
    
    .next-button {
      /* Add your existing styles */
      -webkit-background-clip: text;
      font-size: 30px;
      background-clip: text;
      float: right;
      margin-left: 0px;
      
    }
    
    
    .card-buttons button:hover {
      background-color: #ff3333;
    }
  
    
  }

/*-------- Chat Container --------*/
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .chat-container {
    width: 100vw;
    height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    position: fixed;
    isolation: isolate;
    background-image: url('images/pexels-aleksandar-pasaric-2385210.jpg');
    background-size: cover;
    background-position: center;
  }

  .chat-container::after{
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    background: #fff;
    opacity: 0.8;

  }

  .displayed-message {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .chat-container .chat-container-header {
    background: -moz-linear-gradient(top, #f64f59 0%, #f64f59 50%, #f64f59 51%, #c471ed 100%);
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-container .tab {
    border: none;
    background-color: transparent;
    font-size: 20px;
    margin: 0 px;
    padding: 10px;
    cursor: pointer;
    color: #888888;
    
  }

  .chat-container .tab.active {
    color: #000000;
    border-bottom: 2px solid #000000;
  }
  
  .chat-container .tab-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .chat-container .option:disabled {
    border-bottom: solid 4px rgb(187, 187, 187);
  }
  
  .chat-container .profile,
  .chat-container .log-out-icon {
    display: flex;
    align-items: center;
    padding: 20px;
    color: rgb(126, 3, 120);
  }
  
  .img-container {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin: 10px;
  }
  
  .img-container img {
    width: 100%;
  }

  /* Media Query for Small Screens */
@media screen and (max-width: 1000px) {
  .chat-container .tab {
    font-size: 12px;
    margin: 0 5px;
    padding: 5px;
  }
  
  .displayed-message {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
}

@media screen and (max-width: 700px) {
  
  .chat-container {
    width: 100vw;
    height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    position: relative;
    isolation: isolate;
    background-image: url('images/pexels-aleksandar-pasaric-2385210.jpg');
    background-size: cover;
    background-position: center;
  }

  .chat-container .profile,
  .chat-container .log-out-icon {
    display: flex;
    align-items: center;
    padding: 7px;
    color: rgb(126, 3, 120);
    font-size: 10px;
  }

  .img-container {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin: 5px;
  }
  

  .chat-container .tab {
    font-size: 12px;
    margin: 0 5px;
    padding: 5px;
  }
  
  .displayed-message {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
}
  
  
/*-------- Chat Display --------*/
.chat-display,
.matches-display {
    padding: 20px;
    height: 100vh;
    overflow-y: auto;
}

.match-card {
  background: #a5028a;
  height: 5vh;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 75px;
  margin-bottom: 15px;
}

.match-card h3 {
  font-size: 1em;
}



.chat-message {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the gap value as needed */
  }

.user-message-container {
    margin-left: 60px;
    background-color: #ac2d57;
    padding: 10px;
    color: aquamarine;
    width: fit-content;
    border-radius: 10px;
    height: 4vh;
}
@media screen and (max-width: 800px) {
  .match-card {
    background: #a5028a;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-radius: 20px;
  }
}
/*-------- Chat Input --------*/
.chat-input {
  padding: 20px;
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-items: center; /* Added to vertically align elements */
}

.text-area-container {
  display: flex;
  align-items: center; /* Vertically align elements inside the container */
  padding: 5px;
  flex: 1; /* To occupy available space */
}

.chat-input textarea {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  background-color: #f64f59;
  resize: none;
  color: #fff;
}

.emoji-button {
  font-size: 50px;
  margin-left: 10px; /* Add some spacing between text area and button */
}

.send-button {
  height: 60px;
  width: 70px;
  border-radius: 50px;
  font-size: 25px;
}

/*------- code for responsiveness -----------*/
@media screen and (max-width: 767px) {
  .logo-container h2 {
    background: linear-gradient(260deg, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    background-clip: text;
    margin-right: 140px;
  }
}

@media screen and (max-width: 767px) {
  nav .nav-button {
    background: linear-gradient(260deg,  #12c2e9,#c471ed,#f64f59);
    background-color: rgb(255, 255, 255);
    font-size: 17px;
    padding: 10px 12px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    margin-left: 0%;
}

}

@media screen and (max-width: 767px) {
  .home h1 {
    font-size: 22px;
}
}

@media screen and (max-width: 767px) {
  .home .primary-button {
    color: rgb(255,255,255);
    text-transform: uppercase;
    font-size: 13px;
    background: linear-gradient(45deg, #12c2e9,#c471ed,#f64f59 );
    padding: 10px 26px;
    border-radius: 30px;
    border: none;
  }
}


@media screen and (max-width: 400px) {
  nav .nav-button {
    background: linear-gradient(260deg,  #12c2e9,#c471ed,#f64f59);
    background-color: rgb(255, 255, 255);
    font-size: 12px;
    padding: 8px px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    margin-left: 0%;
}

}
@media screen and (max-width: 400px) {
  .logo-container h2 {
    background: linear-gradient(260deg, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    background-clip: text;
    margin-right: 120px;
  }
}

@media screen and (max-width: 700px) {
  .auth-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin: 0 auto;
    max-width: 360px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 5px;
}

}
@media screen and (max-width: 700px) {
  .auth-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin: 0 auto;
    max-width: 360px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 1px;

}

.auth-modal .close-icon {
  float: right;
}

.auth-modal form h1 {
  align-self: flex-start;
  margin-bottom: 5px;
  margin-left: 40px;
  font-size: 12px;
}

.auth-modal p {
  font-size: 13px;
  
}

.auth-modal h2 {
  font-size: 18px;
}

.auth-modal form {
  display: flex;
  flex-direction: column;
}

.auth-modal input[type=password],
.auth-modal input[type=email] {
  padding: 8px;
  margin-left: 40px;
  font-size: 12px;
  width: 250px;
}

.auth-modal .secondary-button{
  width: 270px;
  margin-left: 40px;
}
}